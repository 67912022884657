export default {
  name: 'Utils',
  /**
   * Функция предназначена для сравнения структуры двух объектов.
   * Работает только с объектами без функций (Plain Object)
   *
   * @param {Object} a
   * @param {Object} b
   * @returns {boolean}
   */
  objectStructEqual(a, b) {
    if (a === b) {
      return true;
    }

    if (a == null || typeof a !== 'object' ||
      b == null || typeof b !== 'object') {
      return false;
    }

    let propsA = [], propsB = [];
    for (let property in a) {
      propsA.push(property);
    }
    for (let property in b) {
      propsB.push(property);
    }
    propsA.forEach((pa) => {
      if (typeof a[pa] === 'object') if (!this.objectStructEqual(a[pa], b[pa])) return false;
      if (!propsB.includes(pa)) return false;
    });
    return true;
  },

  /**
   * Проверяет значение на пустоту или существование (аналог функции PHP)
   * @param {type} value
   * @returns {Boolean}
   */
  empty(value) {
    if (typeof value === 'undefined') return true;
    if (value === undefined) return true;
    if (value === null) return true;
    if (value === '') return true;
    if (value === 0) return true;
    return value.length !== undefined && value.length === 0
  },

  /**
   * Проверяет значение на null и undefined (аналог функции PHP)
   * @param {type} value
   * @returns {Boolean}
   */
  isNull(value) {
    return (typeof value === 'undefined') || (value === undefined) || (value === null)
  },
  authHeader() {
    let user = JSON.parse(localStorage.getItem('vuex'));
    if (user && user.auth.accessToken) {
      return {Authorization: `Bearer   ${user.auth.accessToken}`};
    } else {
      return {};
    }
  },
  authUser() {
    let user = JSON.parse(localStorage.getItem('vuex'));
    if (user && user.auth.accessToken) {
      return user.auth.user;
    } else {
      return {};
    }
  }
}