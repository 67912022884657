export default {
  namespaced: true,
  state: {
    checkFilledContacts: false,
  },
  mutations: {
    ['ADD_STATUS_CHECK_FILLED_CONTACTS'](state, payload) {
      state.checkFilledContacts = payload;
    },
  },
  actions: {
    async fetchCheckFilled({commit}, payload) {
      const {contacts} = payload
      if (contacts && contacts.length > 0 && contacts[0].fio.length > 0) {
        commit('ADD_STATUS_CHECK_FILLED_CONTACTS', true)
      }
    },
  },
  getters: {
    get_check_filled_status(state) {
      return state.checkFilledContacts;
    },
  }
}