export default {
  namespaced: true,
  state: {
    keycloak: null,
    fakeUser: null,
    loading: false,
    orgsLoading: false,
  },
  mutations: {
    ['SET_AUTH_DATA'](state, payload) {
      state.keycloak = payload;
    },
    ['INIT_FAKE_USER'](state, data) {
      state.fakeUser = data;
    },
    ['SET_LOADING_STATUS'](state, data) {
      console.log(data);
      state.loading = data;
    },
  },
  actions: {
    async setAuthData({commit}, payload) {
      commit('SET_AUTH_DATA', payload);
    },
    async initFakeUser({commit}, payload) {
      commit('INIT_FAKE_USER', payload)
    },
    async clearFakeUser({commit}) {
      commit('INIT_FAKE_USER', null)
    },
    async setLoadingStatus({commit}, payload) {
      commit('SET_LOADING_STATUS', payload);
    },
    async logout({commit, state}) {
      if (state.keycloak) {
        state.keycloak.logout();
        commit('SET_AUTH_DATA', null)
      }
    },
  },
  getters: {
    getAuthData(state) {
      return state.keycloak
    },
    getUser(state) {
      return state.keycloak.tokenParsed
    },
    getUserRoles(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles
    },
    getFakeUser(state) {
      return state.fakeUser
    },
    getLoadingStatus(state) {
      return state.loading
    },
    isAdmin(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('ADMIN_ROLE')
    },
    isTest(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('TEST_STP_ROLE')
    },
    isManager(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('CONTENT_MANAGER_ROLE')
    },
    isFounder(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('FOUNDER_ROLE')
    },
    isRegion(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('REGION_ROLE')
    },
    isOrg(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('ORGANIZATION_ROLE')
    },
    isExpert(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('STP_EXPERT_MAIN') ||
        state.keycloak?.tokenParsed?.realm_access?.roles.includes('STP_EXPERT_MNS') ||
        state.keycloak?.tokenParsed?.realm_access?.roles.includes('STP_EXPERT_KOROLEV');
    },
    isCurator(state) {
      return state.keycloak?.tokenParsed?.realm_access?.roles.includes('CURATOR_ROLE ')
    },
    getOrgsStatus: state => state.orgsLoading,
    getUserAccessToken(state) {
      return state.keycloak.token
    }
  }
}