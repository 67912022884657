<template>
  <v-app>
    <CNotificationsFromStore/>
    <!--    <nav-bar/>-->
    <NavBar/>
    <v-main style="background-color: #F5F6F8;">
      <v-container>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import CNotificationsFromStore from "@/components/CNotificationsFromStore";
import {mapActions, mapGetters} from 'vuex';
import NavBar from "@/components/Navigation/navBar";

export default {
  name: 'App',
  components: {CNotificationsFromStore, NavBar},
  props: {
    keycloak: Object
  },
  data() {
    return {
      showModal: true
    }
  },
  computed: {
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      getUserRoles: 'getUserRoles'
    }),
  },
  mounted() {
    this.setAuthData(this.keycloak);
  },
  methods: {
    ...mapActions('keycloak', {setAuthData: 'setAuthData'}),
  }
}
</script>

<style lang="sass">
.tooltip
  cursor: pointer
  position: fixed
  padding: 8px 12px
  border-radius: 4px
  text-align: left
  background: white
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4)
  font-size: 16px
  line-height: 20px
  color: #00599B
  transition: opacity 0.1s ease-out
  z-index: 800
  //max-width: 250px
  //overflow: hidden

main
  .container
    max-width: 1900px !important
    padding: 20px 34px
    min-height: calc(100vh - 60px)
</style>
