<template>
  <div>
    <v-app-bar
      ref="nav_top_bar"
      :extended="false"
      :height="navTopBarHeight"
      app
      color="#FFFFFF"
      elevation="0"
      class="top-bar"
    >
      <p class="top-bar__title">{{ $route.meta.title }}</p>
      <!--      <img src="@/assets/icons/menu.svg" alt="menu" class="top-bar__menu" @mouseenter="showMenu = true">-->
      <!--      <div v-if="showMenu" class="menu" @mouseleave="showMenu = false">-->
      <!--        <p class="menu__header">Конкурсы</p>-->
      <!--        <div class="menu__line"></div>-->
      <!--        <CompetitionLinks/>-->
      <!--        <br>-->
      <!--        <p class="menu__header">Сборы отчётности</p>-->
      <!--        <div class="menu__line"></div>-->
      <!--        <SborLinks/>-->
      <!--        <br>-->
      <!--        <p class="menu__header">Поддержка сайта стипендиат.рф</p>-->
      <!--        <div class="menu__line"></div>-->
      <!--        <StipendiatLinks/>-->
      <!--      </div>-->
    </v-app-bar>
    <v-navigation-drawer
      :fixed="true"
      :width="navDrawerWidth"
      app
      mobile-breakpoint="300"
    >
      <div :style="`min-height: ${navTopBarHeight}px!important`" class="sidebar-logo">
        <router-link to="/">
          <img alt="logo" src="@/assets/icons/logo_ined.svg">
        </router-link>
      </div>
      <nav>
        <div class="sidebar">
          <div style="display:flex; flex-direction: row">
            <router-link :to="{path: '/profile'}">
              <img :data-tooltip="'Профиль'" class="sidebar__profile-img" alt="profile"
                   src="@/assets/icons/profile.svg">
            </router-link>
            <div v-if="showNavigation" class="sidebar-profile">{{ userName }}</div>
          </div>
          <transition name="slide-fade">
            <div>
              <!--              <router-link :to="{path: '/admin'}" v-if="getUserRoles.includes('CONTENT_MANAGER_ROLE')">-->
              <!--                <div-->
              <!--                  class="sidebar-div sidebar-cursor" :style="$route.path === '/admin' ? 'background: #E3EFFA' : ''"-->
              <!--                >-->
              <!--                  <p v-if="showNavigation" style="margin-left: 57px;" class="sidebar-text">Админка</p>-->
              <!--                </div>-->
              <!--              </router-link>-->


              <!--              <router-link :to="{path: '/applicants'}">-->
              <!--                <div-->
              <!--                  class="sidebar-div sidebar-cursor"-->
              <!--                  :style="$route.path === '/applicants' ? 'background: #E3EFFA' : ''"-->
              <!--                  :data-tooltip="'Претенденты на&nbsp;стипендии'"-->
              <!--                >-->
              <!--                  <div class="sidebar-img-container">-->
              <!--                    <img v-if="!hover.properties" style="height: 22px" class="sidebar-img" alt="sbors"-->
              <!--                         src="@/assets/icons/applicants.svg">-->
              <!--                  </div>-->
              <!--                  <p v-if="showNavigation" class="sidebar-text">Претенденты на&nbsp;стипендии</p>-->
              <!--                </div>-->
              <!--              </router-link>-->
              <router-link :to="{path: '/news'}">
                <div
                  class="sidebar-div sidebar-cursor"
                  :style="$route.path === '/news' ? 'background: #E3EFFA' : ''"
                  :data-tooltip="'Новости'"
                >
                  <div class="sidebar-img-container">
                    <img v-if="!hover.news" style="height: 23px" class="sidebar-img" alt="news"
                         src="@/assets/icons/news.svg">
                  </div>
                  <p v-if="showNavigation" class="sidebar-text">Новости</p>
                </div>
              </router-link>
              <router-link :to="{path: '/info'}">
                <div
                  class="sidebar-div sidebar-cursor"
                  :style="$route.path === '/info' ? 'background: #E3EFFA' : ''"
                  :data-tooltip="'Информационная поддержка'"
                >
                  <div class="sidebar-img-container">
                    <img v-if="!hover.info" style="height: 26px" class="sidebar-img" alt="info"
                         src="@/assets/icons/info.png">
                  </div>
                  <p v-if="showNavigation" class="sidebar-text">Информационная поддержка</p>
                </div>
              </router-link>
              <div
                class="sidebar-div sidebar-cursor"
                @click="logout" :data-tooltip="'Выход'"
              >
                <div class="sidebar-img-container">
                  <img
                    v-if="!hover.exit" style="height: 21px" class="sidebar-img" alt="exit"
                    src="@/assets/icons/exit.png">
                </div>
                <p v-if="showNavigation" class="sidebar-text">Выход</p>
              </div>
            </div>
          </transition>
        </div>
      </nav>
      <div
        class="sidebar-toggle"
        @click="showNavigation = !showNavigation"
      >
        <img v-if="!hover.toggle && showNavigation" alt="toggle" src="@/assets/icons/sidebar-toggle-on.svg">
        <img v-if="!hover.toggle && !showNavigation" alt="toggle" src="@/assets/icons/sidebar-toggle-off.svg">
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "navBar",
  // components: {StipendiatLinks, SborLinks, CompetitionLinks},
  data() {
    return {
      hover: {
        exit: false,
        info: false,
        news: false,
        responsible: false,
        properties: false,
        main: false,
        toggle: false
      },
      showNavigation: false, //
      // showMenu: false,
      navTopBarHeight: 60,
    }
  },
  computed: {
    navDrawerWidth() {
      return this.showNavigation ? 301 : 70
    },
    ...mapGetters('keycloak', {
      getAuthData: 'getAuthData',
      getUser: 'getUser',
      // getUserRoles: 'getUserRoles'
    }),
    userName() {
      // return this.getUser.preferred_username
      return this.getUser.given_name
    },
  },
  methods: {
    ...mapActions('keycloak', {keycloakLogout: 'logout'}),
    ...mapActions('keycloak', {clearFakeUser: 'clearFakeUser'}),
    logout() {
      this.clearFakeUser();
      this.keycloakLogout();
    },
  },
}
</script>

<style lang="sass">
.v-navigation-drawer
  transition: opacity 1.5s

.slide-fade-enter-active
  transition: all .8s ease

.slide-fade-enter, .slide-fade-leave-to
  opacity: 0

#app
  ::-webkit-scrollbar
    width: 6px

  ::-webkit-scrollbar-track
    box-shadow: inset 0 0 6px #CFE0EC

  ::-webkit-scrollbar-thumb
    background-color: #00599B
    border-radius: 10px

.sidebar-cursor
  cursor: url(../../assets/icons/cursor.svg), auto

.sidebar-logo img
  cursor: url(../../assets/icons/cursor.svg), auto
  margin-top: 17px
  margin-left: 22px
</style>