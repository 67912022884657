import Vue from 'vue';
import App from './App.vue';
import Vuetify from 'vuetify';
import Scripts from './utils';
import store from './store';
import router from './router';
import Tooltip from '@/utils/Tooltip';
import Keycloak from 'keycloak-js';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import '@/assets/styles/main.sass';

Vue.use(Vuetify);
Vue.prototype.$scripts = Scripts;
Vue.config.productionTip = false;

const tooltip = new Tooltip();
tooltip.initialize();

let initOptions;

// if (process.env.VUE_APP_BUILD_ENV == 'dev') {
//   initOptions = {
//     url: 'https://auth.ined.ru/auth',
//     realm: 'test_sso',
//     clientId: 'test_client',
//     onLoad: 'login-required',
//     messageReceiveTimeout: 2000,
//   };
// } else {
  initOptions = {
    url: 'https://auth.ined.ru/auth',
    realm: 'Ined_users',
    clientId: 'ined_client',
    onLoad: 'login-required',
    messageReceiveTimeout: 2000,
  };
// }

const keycloak = Keycloak(initOptions);

function vueInit(keycloak) {
  new Vue({
    router,
    store,
    vuetify: new Vuetify({
      icons: {
        iconfont: 'mdi',
      },
      theme: {
        themes: {
          light: {
            primary: '#00599B',
            secondary: '#0292e1',
            accent: '#82B1FF',
            error: '#ED393E',
            info: '#618AA7',
            success: '#29A128',
            warning: '#D4990A',
          },
        },
      },
    }),
    render: (h) => h(App, { props: { keycloak: keycloak } }),
  }).$mount('#app');
}

keycloak
  .init({ onLoad: initOptions.onLoad })
  .then((auth) => {
    if (!auth) {
      // Неавторизованный пользователь
    } else {
      vueInit(keycloak);
      setInterval(() => {
        keycloak
          .updateToken(30)
          .then((refreshed) => {
            if (refreshed) {
              console.log('Token refreshed' + refreshed);
            } else {
              console.log(
                'Token not refreshed, valid for ' +
                  Math.round(
                    keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000,
                  ) +
                  ' seconds',
              );
            }
          })
          .catch(() => {
            console.log('Failed to refresh token');
          });
      }, 120000);
    }
  })
  .catch(() => {
    console.log('Authenticated Failed');
    history.back();
  });
