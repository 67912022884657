import Vue from 'vue';
import Router from 'vue-router';
import store from './store/index';

Vue.use(Router);

const routes = new Router({
  mode: 'history',
  // // eslint-disable-next-line no-unused-vars
  // scrollBehavior: function (to, from, savedPosition) {
  //   if (to.hash) {
  //     return {selector: to.hash}
  //     //Or for Vue 3:
  //     //return {el: to.hash}
  //   } /*else if (to.params.rowOrder) { // hash можно слать только в <router-link> :to ?
  //     return {selector: to.params.rowOrder}
  //   } */else {
  //     return {x: 0, y: 0}
  //   }
  // },
  routes: [
    {
      path: '/',
      component: () => import('@/layouts/BasicLayoutComponent'),
      redirect: '/news',
      meta: {
        requiresAuth: true,
        title: '',
      },
      props: true,
    },
    {
      path: '*',
      name: 'notfound',
      component: () => import('@/pages/404.vue'),
    },
    {
      path: '/sbors',
      component: () => import('@/layouts/Sbors'),
      meta: {
        requiresAuth: true,
        title: 'Актуальные сборы',
      },
      props: true,
    },
    {
      path: '/main-2024',
      component: () => import('@/2024/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsMain2024',
          component: () => import('@/2024/View/applicants/ApplicantsView'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantForm2024',
          component: () => import('@/2024/components/applicants/applicantForm/ApplicantForm'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistration2024',
          component: () =>
            import('@/2024/components/applicants/admin/main/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelection2024',
          component: () =>
            import('@/2024/components/applicants/applicantsSelection/main/ApplicantsSelection'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
      ],
    },
    {
      path: '/main',
      component: () => import('@/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsMain',
          component: () => import('@/View/applicants/ApplicantsView'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantForm',
          component: () => import('@/components/applicants/applicantForm/ApplicantForm'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistration',
          component: () =>
            import('@/components/applicants/admin/main/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelection',
          component: () =>
            import('@/components/applicants/applicantsSelection/main/ApplicantsSelection'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии Президента РФ и Правительства РФ',
          },
          props: true,
        },
      ],
    },
    {
      path: '/mns-2024',
      component: () => import('@/2024/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsMns2024',
          component: () => import('@/2024/View/applicants/ApplicantsMnsView'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantFormMns2024',
          component: () => import('@/2024/components/applicants/applicantForm/ApplicantFormMns'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistrationMns2024',
          component: () => import('@/2024/components/applicants/admin/mns/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelectionMns2024',
          component: () =>
            import('@/2024/components/applicants/applicantsSelection/mns/ApplicantsSelectionMns'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
      ],
    },
    {
      path: '/mns',
      component: () => import('@/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsMns',
          component: () => import('@/View/applicants/ApplicantsMnsView'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantFormMns',
          component: () => import('@/components/applicants/applicantForm/ApplicantFormMns'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistrationMns',
          component: () => import('@/components/applicants/admin/mns/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelectionMns',
          component: () =>
            import('@/components/applicants/applicantsSelection/mns/ApplicantsSelectionMns'),
          meta: {
            requiresAuth: true,
            title:
              'Представление списков претендентов на стипендии для малочисленных народов Севера, Сибири и Дальнего Востока',
          },
          props: true,
        },
      ],
    },
    {
      path: '/korolev-2024',
      component: () => import('@/2024/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsKorolev2024',
          component: () => import('@/2024/View/applicants/ApplicantsKorolevView'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantFormKorolev2024',
          component: () => import('@/2024/components/applicants/applicantForm/ApplicantFormKorolev'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistrationKorolev2024',
          component: () =>
            import('@/2024/components/applicants/admin/korolev/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelectionKorolev2024',
          component: () =>
            import(
              '@/2024/components/applicants/applicantsSelection/korolev/ApplicantsSelectionKorolev'
              ),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
      ],
    },
    {
      path: '/korolev',
      component: () => import('@/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsKorolev',
          component: () => import('@/View/applicants/ApplicantsKorolevView'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantFormKorolev',
          component: () => import('@/components/applicants/applicantForm/ApplicantFormKorolev'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistrationKorolev',
          component: () =>
            import('@/components/applicants/admin/korolev/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelectionKorolev',
          component: () =>
            import(
              '@/components/applicants/applicantsSelection/korolev/ApplicantsSelectionKorolev'
            ),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени С.П. Королева',
          },
          props: true,
        },
      ],
    },
    {
      path: '/valiev-2024',
      component: () => import('@/2024/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsValiev2024',
          component: () => import('@/2024/View/applicants/ApplicantsValievView'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantFormValiev2024',
          component: () => import('@/2024/components/applicants/applicantForm/ApplicantFormValiev'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistrationValiev2024',
          component: () =>
            import('@/2024/components/applicants/admin/valiev/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelectionValiev2024',
          component: () =>
            import('@/2024/components/applicants/applicantsSelection/valiev/ApplicantsSelectionValiev'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
      ],
    },
    {
      path: '/valiev',
      component: () => import('@/layouts/SimpleLayout'),
      children: [
        {
          path: '',
          redirect: 'applicants',
        },
        {
          path: 'applicants',
          name: 'ApplicantsValiev',
          component: () => import('@/View/applicants/ApplicantsValievView'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
        {
          path: 'applicantForm/:rowID',
          name: 'ApplicantFormValiev',
          component: () => import('@/components/applicants/applicantForm/ApplicantFormValiev'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
        {
          path: 'konvertRegistration',
          name: 'KonvertRegistrationValiev',
          component: () =>
            import('@/components/applicants/admin/valiev/konverts/KonvertRegistration'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
        {
          path: 'applicantsSelection',
          name: 'ApplicantsSelectionValiev',
          component: () =>
            import('@/components/applicants/applicantsSelection/valiev/ApplicantsSelectionValiev'),
          meta: {
            requiresAuth: true,
            title: 'Представление списков претендентов на стипендии имени К.А. Валиева',
          },
          props: true,
        },
      ],
    },
    {
      path: '/journals-2024',
      name: 'JournalsEditing2024',
      component: () => import('@/2024/components/applicants/admin/journals/JournalEditing'),
      meta: {
        requiresAuth: true,
        title: 'Редактирование журналов',
      },
      props: true,
    },
    {
      path: '/journals',
      name: 'JournalsEditing',
      component: () => import('@/components/applicants/admin/journals/JournalEditing'),
      meta: {
        requiresAuth: true,
        title: 'Редактирование журналов',
      },
      props: true,
    },
    {
      path: '/properties',
      component: () => import('@/layouts/Properties'),
      meta: {
        requiresAuth: true,
        // adminNeedAsOrgAuth: false,
        title: 'Свойства организации',
      },
      props: true,
    },
    {
      path: '/responsible',
      component: () => import('@/layouts/Responsible'),
      meta: {
        requiresAuth: true,
        // adminNeedAsOrgAuth: false,
        title: 'Ответственные исполнители',
      },
      props: true,
    },
    {
      path: '/news',
      name: 'News',
      component: () => import('@/layouts/newsCMS/News'),
      meta: {
        requiresAuth: true,
        // adminNeedAsOrgAuth: false,
        title: 'Новости',
      },
      props: true,
    },
    {
      path: '/info',
      component: () => import('@/layouts/infoContacts/Info'),
      meta: {
        requiresAuth: true,
        // adminNeedAsOrgAuth: false,
        title: 'Информационная поддержка',
      },
      props: true,
    },
    {
      path: '/stipendiatSborContacts',
      component: () => import('@/layouts/StipendiatSborContacts'),
      meta: {
        requiresAuth: true,
        title: 'Сбор контактов ответственных лиц за прием заявок от претендентов',
      },
      props: true,
    },
    {
      path: '/stipendiatSborStories',
      component: () => import('@/View/StipendiatSborStoriesView'),
      meta: {
        requiresAuth: true,
        title: 'Сбор сведений об историях успеха стипендиатов',
      },
      props: true,
    },
    {
      path: '/sborChisl',
      component: () => import('@/layouts/sborChisl/SborChisl'),
      meta: {
        requiresAuth: true,
        title: 'Сбор численности (октябрь 2022, на 2023/24 учебный год)',
      },
      props: true,
    },
    // {
    //   path: '/contacts',
    //   component: () => import('@/View/ViewLK'),
    //   meta: {
    //     requiresAuth: true,
    //     // adminNeedAsOrgAuth: false,
    //     // title: 'Профиль'
    //   },
    //   children: [
    {
      path: '/profile',
      component: () => import('@/layouts/profile/Profile'),
      meta: {
        requiresAuth: true,
        title: 'Информация об ответственных исполнителях',
        // adminNeedAsOrgAuth: false,
        // authAdmin: true
      },
      props: true,
    },
  ],
});

routes.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.isAdmin)) {
    if (
      store.getters['keycloak/getUserRoles'] &&
      store.getters['keycloak/getUserRoles'].find((el) => el === 'ADMIN_ROLE')
    ) {
      next();
    } else {
      next({
        path: '/',
      });
    }
  } else {
    next();
  }
});

export default routes;
