<template>
  <div
    :class="[cssClass.style ? 'c-notifications_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notifications' : '']"
    class="c-notifications">
    <transition-group
      :class="[cssClass.style ? 'c-notifications__list_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notifications__list' : '']"
      class="c-notifications__list" name="c-notifications_animate"
      tag="div">
      <div v-for="message in get_notifications_messages"
           :key="message.id"
           :class="[cssClass.style ? 'c-notification_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification' : '', 'c-notification_' + message.status]"
           class="c-notification">
        <v-icon
          :class="['c-notification__text_' + message.status]"
          :color="message.status">
          {{ choseIcon(message.status) }}
        </v-icon>
        <p
          :class="[cssClass.style ? 'c-notification__text_' + cssClass.style : '', cssClass.position ? cssClass.position + '__c-notification__text' : '', 'c-notification__text_' + message.status]"
          class="c-notification__text">
          {{ message.message }}</p>
      </div>
    </transition-group>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  name: "notifications",
  props: {
    cssClass: {
      type: Object,
      default: function () {
        return {position: '', style: ''};
      },
    },
  },
  methods: {
    choseIcon(status) {
      switch (status) {
        case 'error':
          return 'mdi-close-octagon-outline'
        case 'warning':
          return 'mdi-alert-outline'
        case 'success':
          return 'mdi-checkbox-marked-circle-outline '
        default:
          return 'mdi-message-text'
      }
    }
  },
  computed: {
    ...mapGetters('notifications', {get_notifications_messages: 'get_notifications_messages'})
  },
}
</script>